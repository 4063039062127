import { useState } from "react";
import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormGroup from '@mui/material/FormGroup';
import SendIcon from '@mui/icons-material/Send';
import TextField from "@mui/material/TextField";
import Typography from '@mui/material/Typography';
import { isLinkValid, isLinkNameValid } from "../utils/stringSanitization";

export default function LinkField({ filterLink, addFavLink }) {
  const [name, setName] = useState("");
  const [link, setLink] = useState("");
  const [saveLink, setSaveLink] = useState(false);

  const handleSubmit = async () => {
    let localLink = link;
    let localName = name;
    if (isLinkValid(localLink) && isLinkNameValid(name)) {
      if (localName.length === 0) {
        localName = 'ligasOUT';
      }
      await filterLink(localLink, localName);
      if (saveLink) {
        saveLinkInFavs(localLink, localName);
      }
    } else {
      alert('Datos inválidos.');
    }
  };

  const saveLinkInFavs = (link, name) => {
    const newFavLink = {
      name: name,
      link: link,
    };
    addFavLink(newFavLink);
  }

  return (
    <div>
      <Typography variant="h4" gutterBottom>
        Nueva liga
      </Typography>
      <div>
        <Typography variant="h6" gutterBottom>
          Link de la liga
        </Typography>
        <TextField
          fullWidth
          label="Link de la liga"
          color="primary"
          value={link}
          onChange={e => setLink(e.target.value)}
        />
      </div>
      <br />
      <div>
        <Typography variant="h6" gutterBottom>
          Nombre de la lista (opcional). Sólo usar letras, números, y guiones "-" o guión bajo "_". No usar espacios.
        </Typography>
        <TextField
          fullWidth
          label="Nombre de la liga"
          color="primary"
          value={name}
          onChange={e => setName(e.target.value)}
        />
      </div>
      <br />
      <FormGroup>
        <FormControlLabel control={<Checkbox checked={saveLink} onChange={e => setSaveLink(e.target.checked)} />} label="Guardar en favoritos" />
      </FormGroup>
      <Button variant="contained" endIcon={<SendIcon />} size="large" onClick={handleSubmit}>
        Filtrar
      </Button>
    </div>
  );
}
