import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import DeleteIcon from '@mui/icons-material/Delete';
import IconButton from '@mui/material/IconButton';
import SendIcon from '@mui/icons-material/Send';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

export default function FavLinkCard({ favLink, index, filterLink, removeFavLink }) {
  return (
    <Card>
      <CardContent sx={{ maxWidth: 340, minWidth: 200 }} style={{ margin: "10px" }} >
        <Typography gutterBottom variant="h5" component="div">
          {favLink.name}
        </Typography>
        <Typography variant="body2" color="text.secondary">
          {favLink.link}
        </Typography>
      </CardContent>
      <CardActions>
        <Stack direction="row" spacing={5}>
          <Button variant="contained" endIcon={<SendIcon />} onClick={() => filterLink(favLink.link, favLink.name)} >
            Send
          </Button>
          <IconButton aria-label="delete" color="error" onClick={() => removeFavLink(index)} >
            <DeleteIcon />
          </IconButton>
        </Stack>
      </CardActions>
    </Card>
  );
}
