import { useEffect, useState } from "react";
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import FavLinks from './FavLinks';
import LinkField from './LinkField';
import LoadingBackdrop from '../general/components/LoadingBackdrop';
import { postFilteredLink, getFilteredLink } from '../utils/requests';

export default function LinksPage() {
  // To manage state of favorite links.
  const [favLinks, setFavLinks] = useState([]);

  // Load favorite links from local storage.
  useEffect(() => {
    let storedFavLinks = localStorage.getItem("favLinks");
    if (storedFavLinks) {
      setFavLinks(JSON.parse(storedFavLinks));
    }
  }, []);

  const addFavLink = newFavLink => {
    let localFavLinks = favLinks;
    localFavLinks.push(newFavLink);
    localStorage.setItem("favLinks", JSON.stringify(localFavLinks));
    setFavLinks([...localFavLinks]);
  };

  const removeFavLink = favLinkIndex => {
    let localFavLinks = favLinks;
    localFavLinks.splice(favLinkIndex, 1);
    localStorage.setItem("favLinks", JSON.stringify(localFavLinks));
    setFavLinks([...localFavLinks]);
  };

  // State to display feedback signaling that the page is loading or doing some work.
  const [isPageLoading, setIsPageLoading] = useState(false);

  // Function to filter a link in the backend and retrieve it.
  const handleFilterLink = async (link, name) => {
    setIsPageLoading(true);
    try {
      await postFilteredLink(link, name);
      getFilteredLink(name);
    } catch(error) {
      alert(`Error ${error.requestStatus} - ${error.status}`);
    }
    setIsPageLoading(false);
  };

  return (
    <Container>
      <Typography variant="h3" component="div" gutterBottom>
        Filtrador de ligas
      </Typography>
      <LinkField filterLink={handleFilterLink} addFavLink={addFavLink} />
      <br />
      <FavLinks favLinks={favLinks} filterLink={handleFilterLink} removeFavLink={removeFavLink} />
      <LoadingBackdrop isLoading={isPageLoading} />
    </Container>
  );
}
