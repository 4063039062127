import { URLs } from "./URLs"

export const postFilteredLink = async (link, linkName) => {
  return fetch(URLs.links, {
    method: 'POST',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({ linkAddress: link, linkName: linkName })
  })
    .then(async response => {
      const data = await response.json();
      data.requestStatus = response.status;
      if (!response.ok) {
        throw data
      }
    });
}

export const getFilteredLink = (linkName) => {
  const link = document.createElement("a");
  link.setAttribute('download', linkName);
  link.href = URLs.links + `/${linkName}`;
  link.click();
}
