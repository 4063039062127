import {
  BrowserRouter,
  Routes,
  Route
} from "react-router-dom";
import './App.css';
import LinksPage from './modules/links/LinksPage';
import NotFound from "./modules/utils/errors/NotFound";

function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
          <Route path="/">
            <Route index element={<LinksPage />} />
            <Route path="*" element={<NotFound />} />
          </Route>
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
