import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from '@mui/material/Typography';
import FavLinkCard from "./FavLinkCard";

export default function FavLinks({ favLinks, filterLink, removeFavLink }) {
  return (
    <div>
      <Typography variant="h4" gutterBottom>
        Ligas favoritas
      </Typography>
      <div style={{ display: "flex", justifyContent: "center" }}>
        <Box sx={{ flexGrow: 1 }}>
          <Grid container spacing={1}>
            {favLinks.map((link, index) => {
              return (
                <Grid key={index} item xs={12} sm={4} md={3}>
                  <FavLinkCard favLink={link} index={index} filterLink={filterLink} removeFavLink={removeFavLink} />
                </Grid>
              );
            })}
          </Grid>
        </Box>
      </div>
    </div>
  );
}
